.method
    display flex
    align-items: center
    justify-content flex-start
    padding 30px 0
    box-shadow: 0 0 87px rgba($dark, .14)
    border-radius: 24px
    &__icon
        position absolute
        top 0
        //right 0
        width 160px
        height 110px
        color $primary
        opacity 0.2
        &-wrapper
            position relative
            width 140px
            height 120px
            flex-shrink: 0
            overflow hidden
            margin-right 10px
    &__text
        width 100%
        line-height 1.5
        letter-spacing .025em

@media $max-xxs
    .method
        padding 20px 0
        &__icon
            width 90px
            height 90px
            &-wrapper
                width 71px
                height 90px
                margin-right 20px
