.intro
    position relative
    padding: 157px 0 10px
    background url(../img/bg-intro.png) top right no-repeat
    background-size contain
    &__title
        margin-bottom .5em
        letter-spacing .025em
        line-height 1.4
    &__text
        margin-bottom 2.4em
        font-weight normal
        font-family: Montserrat;
        font-style: normal;
        letter-spacing .1em
        font-size: 1.8rem;
        a
          font-size: 1.8rem;
          font-weight: normal;
          font-family: 'Montserrat';
          border-bottom: 1px solid #78A961;
          &:hover
              font-weight: bold;
    &__btn
        max-width 460px
        background: linear-gradient(360deg, #78A961 -45.03%, #CEFF67 108.94%);
        box-shadow: 0px 15px 35px rgba(117, 160, 73, 0.31);
        &:hover
            box-shadow: none
    &__note
        max-width 390px
        margin-bottom 120px
        text-align center
    &__list
        width 100%
        display flex
        justify-content space-between
        align-items: flex-start
        margin-bottom -165px
    &__item
        width 30%
        max-width 320px
        margin-bottom 20px


@media $max-md
    .intro
        padding-top 170px
        padding-bottom 120px
        text-align center
        &__list
            flex-wrap: wrap
            justify-content: space-around
            margin-bottom 0
        &__item
            width 320px
            max-width 100%
            margin-bottom 30px
        &__note
            max-width 100%
            margin-bottom 90px

@media $max-xs
    .intro
        padding-bottom 60px
        &__btn
            max-width 360px
        &__note
            margin-bottom 60px

@media $max-xxs
    .intro
        &__btn
            max-width 320px
