fw($width = 100%)
    width $width
    max-width $width
    flex-basis: $width

fs()
    display: flex
    align-items: stretch;
    justify-content: stretch; 

bc($width, $mb = 0)
    width 100%
    max-width: $width
    margin 0 auto $mb



trc($time = .28s)
    transition: color $time ease-out

tro($time = .28s)
    transition: opacity $time ease-out

trt($time = .28s)
    transition: transform $time ease-out

trbg($time = .28s)
    transition: background $time ease-out

trbgc($time = .28s)
    transition: background $time ease-out, color $time ease-out

trb($time = .28s)
    transition: border-color $time ease-out

trbgo($time = .28s)
    transition: background $time ease-out, opacity $time ease-out

trbsh($time = .28s)
    transition: box-shadow $time ease-out

trbgsh($time = .28s)
    transition: background $time ease-out, box-shadow $time ease-out


dots($first = #560862, $second = #800d92, $primary = #ad11c3)
    span
        display: block
        border-radius: 50%
        margin-left: auto
        margin-right: auto
        animation-iteration-count: infinite
        animation-timing-function: ease-out
        animation-duration: 3s
        animation-name: appear
        opacity: 0
        &:nth-child(3n+1)
            width: 8px
            height: 8px
            margin-bottom: 12px
            background-color: $first
            animation-delay: 0
        &:nth-child(3n+2)
            width: 10px
            height: 10px
            margin-bottom: 10px
            background-color: $second
            animation-delay: .5s
        &:nth-child(3n+3)
            width: 13px
            height: 13px
            background-color: $primary
            animation-delay: 1s

anim-border($color)
    &::before
        content ''
        position absolute
        top -34px
        left 34px
        width: 0;
        height: 0;
        border-left: 16px solid transparent
        border-right: 16px solid transparent
        border-bottom: 20px solid rgba($color)
        animation: up-down-border 1.6s ease-out infinite
    &::after
        content: ''
        position: absolute
        top -14px
        left -14px
        bottom -14px
        right -14px
        display: block
        border 14px solid rgba($color)
        animation: up-down-border 1.6s ease-out infinite


media-desktop()
    @media screen and (max-width 1190px)        
        {block}

media-tablet()
    @media screen and (max-width 1000px)        
        {block}

media-mobile()
    @media screen and (max-width 520px)        
        {block}


btn-ripple()
    animation: ripple 1.8s infinite linear
    -webkit-animation-play-state: running
    -animation-play-state: running
    &:hover
        -webkit-animation-play-state: paused
        animation-play-state: paused

btn-blick()
    position relative
    overflow hidden
    &:hover
        &::after
            opacity 0
    &::after
        content ''
        position absolute;
        top -10px
        bottom -15px
        left -15px
        display block
        margin-left -1px
        box-shadow 0 0 15px 3px #fff
        background 0 0
        transform rotate(38deg);
        animation blick 2s ease-out infinite
        opacity 1
        tro()

svg-move($n = 4, $d = .6s)
    delay = 0s
    for num in (1..$n)
        &:nth-child({$n}n+{num})
            img
            svg
                animation-delay: delay
        delay = delay + $d

before-move($n = 4, $d = .6s)
    delay = 0s
    for num in (1..$n)
        &:nth-child({$n}n+{num})
            &:before
                animation-delay: delay
        delay = delay + $d

anim-move($n = 4, $d = .6s)
    delay = 0s
    for num in (1..$n)
        &:nth-child({$n}n+{num})
            ._anim-move
                animation-delay: delay
        delay = delay + $d


hor-line($width = 493px)
    position relative
    &::after
        content: ''
        position: absolute
        bottom -10px
        left -130px
        display block
        width $width
        height 4px
        background-color $primary

line()
    position relative
    &::after
        content: ''
        position: absolute
        top 50%
        transform translate(-100%,-45%) skewX(33deg)
        display block
        height 90px
        width 8px
        background-color $primary

btn-ripple()
    animation: ripple-btn 1.8s infinite linear
    -webkit-animation-play-state: running
    -animation-play-state: running
    &:hover
        -webkit-animation-play-state: paused
        animation-play-state: paused        

scrollbar($color = #636467, $bg = #fafafa)
    &::-webkit-scrollbar-track
        -webkit-box-shadow: inset 0 0 0 3px $bg;
        background-color rgba($color , .5)

    &::-webkit-scrollbar
        width: 8px; 

    &::-webkit-scrollbar-thumb
        background-color rgba($color , .75)