.logo
    opacity 0.5
    &__title
        margin-bottom: .1em
        font-family 'Merriweather'
        font-size: 3rem
        letter-spacing 0.22em
        text-transform uppercase
        ._thanks &
            font-size: 3rem
    &__text
        font-size: 3rem
        font-family: 'Montserrat'
        ._thanks &
            font-size: 1.4rem


@media $max-sm
    .logo
        &__title
        &__text
            font-size: 2.4rem

@media $max-xs
    .logo
        display flex
        align-items: center
        justify-content center
        ._thanks &
            display block
        &__title
        &__text
            font-size: 2rem
