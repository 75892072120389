.thanks
    padding-top 150px
    padding-bottom 320px
    background: url(../img/bg-thanks.png) bottom right no-repeat

    min-height: calc(100vh - 100px)
    &__title
        margin-bottom 0.7em
        letter-spacing .05em
    &__subtitle
        margin-bottom 3.3em
        letter-spacing .1em
        font-weight normal
        font-style italic
        font-family: 'Montserrat'
    &__text
        font-family: 'Montserrat'
        margin-bottom 1.5em
        letter-spacing .025em
        font-weight normal
        line-height: 1.55
        a
            color $primary
            font-weight bold
            font-size: inherit
            opacity 1
            tro()
            &:hover
                opacity 0.5
    &__inst
        margin-bottom 1em
        letter-spacing .025em
        line-height: 1.75
        font-family: 'Montserrat'
    &__btn
        display inline-block
        color: $primary
        font-family 'Montserrat'
        font-size: 18px
        opacity 1
        tro()
        &:hover
            opacity 0.5
        span
            display inline-block
        &-icon
            width 30px
            height 30px
            margin 0 8px
            padding-top 4px
            display inline-block
            vertical-align middle
            background-color currentColor
            border-radius: 50%
            background-color #fff9ee

._thanks .footer
    background: none;
    .footer__row
        flex-direction: column;
        align-items: flex-start;
    .call__tel
        padding: 0
    .footer__text
        padding: 33px 0 22px
@media (min-width 1920px)
    .thanks
        display flex
        align-items: center
        justify-content: flex-start

@media $max-md
    .thanks
        padding-top 180px
        padding-bottom 185px
        display flex
        align-items: center
        justify-content: center

@media $max-sm
    .thanks
        padding-top 100px
        padding-bottom 60px
        text-align center
        background-position bottom left
        &__subtitle
            margin-bottom 2em

@media $max-sm
    .thanks
        br
            display none

@media $max-xs
    .thanks
        min-height: calc(100vh - 145px)
    ._thanks .footer__row
        align-items: center!important;
