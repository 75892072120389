.specialists
    padding-top 100px
    padding-bottom 80px
    margin-bottom -120px
    &__text
        margin-bottom 1em
        font-weight normal
        letter-spacing .2em
        text-align center
        opacity 0.6
    &__title
        margin-bottom 1.75em
        letter-spacing .025em
        line-height 1.4
        text-align center
    &__list
        display flex
        justify-content center
        align-items: flex-start
        margin-bottom 50px
    &__item
        width 50%
        margin: 0 10px
        max-width 410px


@media $max-md
    .specialists
         padding-top 80px

@media $max-sm
    .specialists
        padding-top 60px
        &__list
            flex-wrap: wrap
            justify-content: space-around
            margin-bottom 0
        &__item
            width 320px
            max-width 100%
            margin-bottom 50px
