.form
    position relative
    z-index: 1
    width 100%
    max-width 525px
    padding  48px 40px 0px
    background: #78D585;
    border-radius: 50px;
    &__title
        margin-bottom 2em
        letter-spacing .025em
        color: #fff

        font-family: 'Open Sans';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
    &__content
        max-width 348px
        width 100%
        margin auto
    &__label
        position relative
        display flex
        align-items: center
        flex-direction: row-reverse
        width 100%
        max-width 340px
        padding 18px 35px
        margin 0 auto 10px
        background: #FFFFFF;
        border-radius: 50px;
    &__select
        width: 100%
    &__select

    &__field
        flex-grow 1
        font-size: 1.4rem
        color rgba($dark, .4)
        font-family $ffG
        font-weight 300
        &:focus
            color rgba($dark, .8)
            &+.form__icon
                color $primary
    &__icon
        width 16px
        height 16px
        margin-right 17px
        flex-shrink: 0
        color: #c3c3c3
    &__btn
        max-width 342px
        min-height: 100px;
        padding: 18px 36px 22px;
        margin 20px auto 0
        font-size: 2rem
        &__wrap
          background: url('../img/form-ellipse.png');
          height: 260px;
          margin: 0 -40px;
          padding-top: 50px;
          border-bottom-left-radius: 50px
          border-bottom-right-radius: 50px

@media(max-width: 980px)
    .form
        margin 0 auto
@media(max-width: 480px)
    .question__list__item
        height: auto!important;
        padding: 20px 0 20px
        padding-left: 30px!important
        font-size: 16px!important
    .question__list__item__dropdown
        margin: 0!important
        padding: 20px 10px 35px!important
        font-size: 14px!important;
@media $max-sm
    .form
        padding  24px 20px 0px
        box-shadow: 0 0 60px rgba($primary, .14)
        &__field
            color rgba($dark, .6)
        &__label
            padding 12px 25px
    .consult__img
        width: 100%
    .specialist__img
        width: 100%!important;
@media $max-xxs
    .form
        &__btn
            max-width 300px
            min-height 82px
            padding: 10px 20px 12px
            font-size: 1.8rem
            border-radius: 40px
@media(max-width: 540px)
    .form__btn__wrap
        padding: 50px 20px 0
        background: #fff
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
@media(max-width: 425px)
    .modal-deseases
        padding: 56px 0 45px 20px!important
        h4
          font-size: 17px
        ul
          font-size: 13px
