.slider
    .slick-track
        display flex
        align-items: center
    .slick-dots
        display flex
        position: absolute
        bottom -20px
        left 50%
        transform: translateX(-50%) 
        .slick-active
            button 
                font-size: 1.6rem
                color $primary
                &::before
                    background-color $primary
                    transform translateX(-50%) scale(1)
        button 
            position: relative
            padding 0 10px
            color rgba($dark, .4)
            font-size: 1.3rem
            font-family $ffG
            &:hover
                color $primary
            &::before
                content: ''
                display block
                height 42px
                width 3px
                margin 0 auto 8px
                trt()
                transform-origin center bottom 
                transform  scaleY(.52) scaleX(.66)
                background-color currentColor
    &__slide
        position relative
        padding-top 80px
        padding-bottom 80px
        opacity 0
        tro()
        &.slick-active 
            opacity 1
        &.slick-center
            z-index 2
    &__btn
        position absolute
        bottom 118px
        display: flex
        align-items center
        width: 270px
        padding-top: 240px
        &:hover
            .slider__btn-text
                    opacity 1
            &._prev
                    .slider__btn-icon
                        transform: translateX(-10px)
            &._next
                    .slider__btn-icon
                        transform: rotate(180deg) translateX(-10px)

        &._prev
            right calc( 50% + 320px)
            justify-content flex-end  
            padding-right 35px  
        &._next
            left calc( 50% + 320px)
            justify-content flex-start
            padding-left 35px
        &-icon
            width 20px
            height 15px
            transform: translateX(0)
            color: $primary
            trt()
            ._next &
                transform: rotate(180deg) translateX(0)
        &-text 
            opacity 0.5
            tro()
            font-size: 1.3rem
            letter-spacing .025em 
            font-style italic

.slide
    &__header
        width 330px
        margin: 0 auto
        padding 50px 108px 50px 28px
        background: $light
        box-shadow: 0 0 73px 13px rgba(#4a3e27, .1)
        border-radius 25px
        text-align center
        ._text    
            opacity .5
        .slick-center + .slider__slide  &
            padding 50px 28px 50px 108px
        .slick-center &
            width: 640px 
            margin: 0 -107px
            padding 30px 40px
            box-shadow: 0 0 73px 13px rgba(#4a3e27, .19)
            ._text    
                opacity 1
    &__title
        margin-bottom 0.5em
        letter-spacing 0.05em
        font-size: 1.6rem
        .slick-center &
            font-size: 2rem
    &__diagnosis
        margin-bottom 2em
        letter-spacing 0.1em
        font-size: 1.1rem
        font-style italic
        .slick-center &
            font-size: 1.2rem
    &__text
        position relative
        opacity 0.5  
        display none 
        margin-bottom 1.2em
        line-height 1.35
        letter-spacing 0.025em
        &::before
        &::after
            content: ''
            position absolute
            top 0
            display block
            width 44px
            height 30px
            background: url(../img/icon-quote.png) center center no-repeat
            background-size contain
        &::before
            left 0
            transform: translateY(-100%)
        &::after    
            right 0
            transform: scaleX(-1) translateY(-100%)
        .slick-center &
            display block
    &__prove
        display none 
        letter-spacing 0.025em
        .slick-center &
            display block
    &__link
        color $primary
        letter-spacing 0.025em


@media $max-md 
    .slider
        .slick-dots
            bottom -30px
            .slick-active
                button 
                    font-size: 1.4rem
            button 
                font-size: 1.4rem
                &::before
                    height 36px
                    width 2px
        &__slide
            padding-top 60px
            padding-bottom 60px
            &.slick-active 
                opacity 0
            &.slick-center
                opacity 1
        &__btn
            bottom 0
            &._prev
                right calc( 50% + 160px)
                padding-right 0  
            &._next
                left calc( 50% + 160px)
                padding-left 0
            &-icon
                width 16px
               

    .slide
        &__header
            width 100%
            max-width 640px
            margin: 0 auto
            box-shadow: 0 0 73px 13px rgba(#4a3e27, .1)
            .slick-center &
                width 100%
                max-width 640px
                margin: 0 auto
                padding 30px 40px
                box-shadow: 0 0 53px 10px rgba(#4a3e27, .19)
                ._text    
                    opacity 1

@media (max-width 680px)
    .slider
        position relative
        .slick-track
            align-items: flex-start
        &__slide
            padding 20px 26px
        &__btn
            z-index 5
            padding 0
            width auto
            display: flex
            align-items: center
            justify-content: center
            width 26px
            height 26px
            border 1px solid $primary
            border-radius: 50%
            &._prev
                top: 30%
                left -4px
                right auto
            &._next
                top: 30%
                right -4px
                left: auto
            &-text
                display none
            &-icon
                width 20px
                height 20px    
   
    .slide
        &__header
            .slick-center &
                padding 24px 10px
                box-shadow: 0 0 23px  rgba(#4a3e27, .1)
        &__title
            font-size: 1.5rem
            .slick-center &
                font-size: 1.6rem
        &__text
            br
            &::before
            &::after
                display none