.card
    min-height: 180px
    //background-color $bgLight
    &__wrap
        background-color $bgLight
        box-shadow: 0px 0px 20px rgba(201, 201, 201, 0.31);
        border-radius 24px
        padding-top: 3rem
    &__icon
        display block
        width 30px
        height 30px
        color $light
        margin 0 auto
        &-wrapper
            width 170px
            max-width 80%
            padding 14px
            margin 0 auto -10px
            background: #F8FFF7;
            box-shadow: 0px 0px 20px rgba(189, 189, 189, 0.21);
            border-radius: 10px;
    &__title
        display block
        margin 0 auto
        font-family: "Montserrat";
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #78A961;
    &__text
        margin-top 3rem
        width 100%
        text-align center
        letter-spacing .025em
        line-height 1.4
        padding-bottom 3.5rem

@media $max-xs
    .card
        border-top-right-radius: 6px
        border-top-left-radius: 6px
        padding-bottom 18px
        &__icon
            width 24px
            height 24px
            &-wrapper
                width 120px
                padding 10px
                box-shadow: 0 28px 67px rgba(#423b33, .007)
                border-bottom-right-radius: 6px
                border-bottom-left-radius: 6px
