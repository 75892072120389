.how
    padding-top 300px
    padding-bottom 85px
    //background: url(../img/how-bg.png) top left no-repeat
    background-size contain
    position: relative
    margin-bottom -380px
    &:before
      content: " "
      height: 713px
      position: absolute;
      top: 450px
      right: 0
      left: 0
      background: #F8FFF7
      z-index: 0
    &__bg{
      position: absolute;
      top: 150px;
      z-index: 1
    }
    &__text
        margin-bottom .8em
        letter-spacing .2em
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.05em;
        color: #5A5A5A;
        position: relative;
        display inline-block
        &:before, &:after
            content ' '
            width: 3px
            height: 3px
            background: #87D37C
            display inline-block
            position: absolute
            right: 0
            left: 0
            margin 0 auto
        &:before
            top -10px
        &:after
            bottom: -10px
    &__title
        margin-bottom 1.9em
        letter-spacing .025em
        line-height 1.4
        &:after
            content: none!important
    &__row
        display flex
        //justify-content space-between
        //align-items: center
        margin-bottom 108px
        //background-color rgba($primary, .1)
    &__img
        width 350px
        height: 100%
        max-width 45%
        flex-shrink: 0
        border-radius: 50px;
        margin-right 50px
        margin-top 60px
        z-index: 3
    &__description
        flex-grow 1
        letter-spacing .025em
        line-height 1.5
        padding-right 20px
        padding-top 16px
        padding-bottom 16px


@media $max-lg
    .how
        &__description
            br
                display none

@media $max-md
    .how
        padding-top 200px
        &__row
            margin-bottom 68px
            flex-wrap: wrap
            background-color transparent
        &__img
            //width 480px
            max-width 86%
            margin: 0 auto 20px
        &__description
            width 100%
            max-width 100%
            text-align center
            padding-right 0
        &__wrap
            text-align center

@media $max-xs
    .how
        padding-top 100px
        padding-bottom 60px
