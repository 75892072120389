.mfp
    &-bg
        background-color $dark
        opacity 0.6

.modal
    position relative
    z-index: 1
    display block
    width 100%
    max-width 525px
    margin 0 auto
    &__close
        cursor pointer
        position: absolute
        z-index 1
        top 24px
        right 24px
        display block
        width 14px
        height 14px
        color #fff
        // background-position center center
        // background-size 20px 20px
        // background-repeat no-repeat
        // background-image url('../img/icons/close.dark.svg')
        // opacity 1
        trc()
        &:hover
            color #ffb6b6
    &-deseases
        background: #F8FFF7;
        border-radius: 17px;
        width: 100%
        max-width: 515px
        padding: 60px 0 50px 60px
        .modal__close
            color: #A0D2A7;
        h4
          font-weight: bold;
          font-size: 20px;
          color: #A0D2A7;
        ul
          margin-top 15px
          font-size: 18px;
          color: #5A5A5A;
          line-height 30px
          li
            &:before
              content: " "
              background: #A0D2A7;
              height: 6px
              width: 6px
              display inline-block
              margin-right 30px
              vertical-align middle
              border-radius: 10px
.modal-consult
    &::before
        content: ''
        position absolute
        top 0
        bottom 0
        left calc(50% - 398px)
        display block
        width 796px
    .form
        max-width 100%
        margin 0 auto


@media (max-width: 800px)
    .modal-consult
        &::before
            display none

// .modal-agree
//     width calc(100% - 30px)
//     max-width 780px
//     padding 48px 60px 42px
//     margin 20px auto
//     border-radius: 50px
//     box-shadow: 0 0 70px rgba($dark, .19)
//     background $light
//     &__title
//         margin-bottom 1.1em
//     &__text
//         line-height 1.4
//         letter-spacing .025em
//         margin-bottom .4em
//     &__link
//         text-decoration: underline
//         &:hover
//             text-decoration none


.modal-thanks
    padding  65px 30px
    background-color $light
    box-shadow: 0 0 87px rgba($primary, .14)
    border-radius: 8px
    text-align center
    &__title
        margin-bottom 1em
        font-size: 2.2rem
        letter-spacing .025em
        line-height 1.4
        &::after
            content: ''
            display block
            width 80px
            height 2px
            margin: 8px auto 0
            background-color $primary
    &__text
        font-family $ffPD
        letter-spacing .025em

@media(max-width: 780px){
  .form__btn__wrap{
    margin: 0 -20px!important;
  }
}
