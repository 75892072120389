.nav
    &__list
        display: flex
        justify-content: space-between
        align-items: center
        list-style: none
    &__item
        &:not(:last-child)
            margin-right 43px
    &__href
        cursor pointer
        position: relative
        display: block
        padding 2px 0
        letter-spacing: .02em
        font-family 'Montserrat'
        font-weight normal
        color: #5A5A5A
        position relative
        &:before, &:after
            content ' '
            width: 3px
            height: 3px
            background: #87D37C
            display inline-block
            position: absolute
            right: 0
            left: 0
            margin 0 auto
            opacity: 0
            transition: opacity .3s
        &:before
            top -10px
        &:after
            bottom: -10px
        &:hover
            color: #000
            &:before, &:after
                opacity: 1


    &__btn-open
        display: none


@media $max-md
    .nav
        &__btn-open
            display block
            cursor pointer
            padding 10px
            &::after
                content: 'Открыть меню'
                display: block
                font-size: 1.4rem
                text-align: center
                color: inherit
                ._open &
                    content: 'Закрыть меню'
        &__list
            display: none
            position: absolute
            z-index 2
            bottom: 0
            left: 0
            right: 0
            display: block
            width: auto
            background-color:  $bgLight
            box-shadow: 0 5px 10px rgba($dark, .2)
            transform: translateY(100%) scale(1, 0)
            transform-origin: top center
            trt()
            ._open &
                display block
                transform: translateY(100%)  scale(1, 1)
        &__item
            &:not(:last-child)
                margin-right 0
        &__href
            padding 12px
            border none
            text-align center
            font-style normal

@media $max-sm
    .nav
        &__btn-open
            &::after
                font-size: 1.2rem
