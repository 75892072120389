.header
    position absolute
    z-index 2
    top 0
    left 0
    right 0
    &._sticky
        position fixed
        left 0
        right 0
        top 0
        z-index 10
        background-color $bgLight
        box-shadow: 0 0 20px rgba($dark, .4)
        ._thanks &
            box-shadow: none
            background: transparent
            position abdolute
    &__row
        padding 25px 0
        display flex
        align-items center
        justify-content space-between
        ._thanks &
            justify-content: flex-start
            padding-top 40px
    &__nav
        margin-left 145px
        margin-right auto
    //     border-top 2px solid rgba($dark, .09)   


@media $max-lg
    .header
        &__nav
            margin-left auto
    .call
    .logo
        width 220px        

@media $max-md
    .header
        &__row
            padding 20px 0

@media $max-sm
    .header
        &__row
            padding 8px 0 10px
            ._thanks &
                justify-content: center
                padding-top 10px
    .call
        margin-top 0
        width 180px 
        &__tel
            padding 4px 6px
    .logo
        width 180px 

@media $max-xs
    .header
        &__row
            padding 0
            flex-wrap: wrap
        &__nav
            order 3
            width 100%    
        .call
            text-align center
            margin-left auto
            margin-right auto
            margin-top 4px
            margin-bottom -2px
            width 180px
            
        .logo
            width 100%
            text-align center