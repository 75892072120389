.consult
  padding-top: 300px;
  padding-bottom 250px
  background: url('../img/consult-bg.png');
  background-size: 100%;
  background-repeat: no-repeat;
  &__title
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
  &__info
    &__title
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.05em;
      color: #78A961;
  &__invite
    margin-top 70px
  &__img
    margin 0 auto
  &__video
    z-index: 2;
  &__wrap
    margin-top 170px
    display: flex;
    justify-content space-between
    div
      flex: 1 1 auto;
  &__short
    position: relative;
    z-index: 1;
  &__video-row
    text-align center
    background: #fff;
    position: absolute;
    bottom: -90px;
    padding-top 45px
    top: -120px;
    z-index: -1;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 20px rgba(160, 210, 167, 0.25);
    border-radius: 50px;
    max-width: 415px;
    margin 0 auto
  &__list
    margin-top 30px
    &__item
      margin-bottom 60px
      font-size: 18px;
      line-height: 27px;
      position: relative;
      padding-left: 25px
      &:before
        content: " "
        width: 11px
        height: 11px
        display inline-block
        position: absolute;
        top 5.5px
        left: 0
        border-radius: 10px
        background: linear-gradient(360deg, #78A961 -45.03%, #CEFF67 108.94%);
      & p span
        font-weight: bold;


@media $max-md
    .consult
        &__wrap
            flex-direction: column
            align-items: center
        &__info
            text-align center
