.contact
    margin-top -170px
    padding-top 216px
    padding-bottom 170px
    //background url(../img/bg-contact.png) top center no-repeat
    //background-size 100% 100%
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 15.29%), #F2FFF0;
    &__text
        margin-bottom 1.95em
        font-weight normal
        letter-spacing .1em
        text-align center
        color: #6D6D6D;
        font-family: 'Montserrat';

    &__title
        line-height: 52px
        text-align center
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        letter-spacing: 0.02em;
        color: #6D6D6D;
    &__row
        display flex
        justify-content center
        align-items: center;
    &__form
        position relative
        //width 50%
        //max-width 420px
        //margin-left 110px
        margin-right: 90px;
    &__info
        width 500px
        padding-left 25px
    &__link
        display flex
        align-items: center
        justify-content flex-start
        margin-bottom 40px
        letter-spacing .05em
        &._adress
            line-height 1.65
        &-text
            span
                display block
                letter-spacing .05em

    &__icon
        display inline-block
        width 22px
        height 22px
        margin-right 22px
        flex-shrink: 0
        color #A0D2A7;
        fill: #A0D2A7;


@media $max-lg
    .contact
        &__form
            &::before
                left -20%
                width 140%
                transform: translateX(0)

@media $max-md
    .contact
        padding-top 156px
        padding-bottom 120px
        background-size auto 100%
        &__row
            flex-wrap: wrap
        &__form
            width 100%
            margin: 0 auto 30px
        &__info
            width 400px
            max-width 100%
            margin: 0 auto 30px

@media $max-sm
    .contact
        padding-top 36px
        padding-bottom 10px
        &__link
            margin-bottom 20px
            br
                display none
        &__icon
            width 20px
            height 20px
            margin-right 16px


@media $max-xs
    .contact
        &__form
            &::before
                display none
