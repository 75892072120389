.footer
    padding: 32px 0 36px
    ._thanks &
        position fixed
        bottom 0
        left 0
        right 0
    &__row
        display: flex
        align-items: center
        justify-content: space-between
    &__text
        padding 10px 0
        font-size 1.4rem
        opacity 0.2
    &__logo
        flex-shrink: 0
    &__call
        .call__icon
            color: #5A5A5A;  

@media $max-sm
    .footer
        padding 24px 0

@media $max-xs
    .footer
        ._thanks &
            position: relative
        &__row
            flex-wrap: wrap
            justify-content: space-around
        .logo
            width 100%
            text-align center
