.specialist
    position relative
    display block
    &:hover
        .specialist__info
            background-color $primary
            color: #fff
        .specialist__btn
            background-color $light
            //color $primary
    &__img
        width 100%
        border-top-left-radius: 24px
        border-top-right-radius: 24px
    &__info
        padding 12px 28px 16px
        color: #5A5A5A
        text-align center
        background-color #F8FFF7;
        trbg()
        min-height: 170px
    &__title
        letter-spacing .05em
        line-height 1.5
        font-family: $ffPD
    &__text
        margin-bottom 1em
        font-family: 'Montserrat'
        font-weight normal
        letter-spacing .05em
    &__btn
        width 170px
        margin-left: auto
        margin-right: auto
        //padding 5px 15px
        background: linear-gradient(360deg, #78A961 -45.03%, #CEFF67 108.94%);
        box-shadow: 0px 15px 35px rgba(117, 160, 73, 0.31);
        background-color $primary
        trbgc()

        height: 40px;
        font-weight: normal;
        font-size: 14px;
        line-height 40px
        border-radius: 100px
        letter-spacing: 0.05em;
        color: #FFFFFF;
    &__modal
        position absolute
        z-index 1
        top 0
        bottom 0
        left 0
        right 0
        padding 14px 10px
        display: flex
        align-items: center
        justify-content: center
        background-color #fff9ee
        opacity 0
        tro()
        ._active &
            opacity 1
        &-content
            position relative
        &-title
            margin-bottom 0.5em
        &-text
            &:not(:last-child)
                margin-bottom 1.5em
        &-close
            cursor pointer
            position: absolute
            z-index 1
            top 14px
            right 14px
            display block
            width 14px
            height 14px
            color $primary
            trc()
            &:hover
                color #ffb6b6


@media $max-sm
    .specialist
        &__img
            width 90%
        &__info
            padding 10px 20px 12px
