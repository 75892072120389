.question
  background: url('../img/question-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  padding: 350px 0 200px;
  &__title
    margin-top 0
  &__list
    max-width: 850px
    margin 0 auto
    &__item
      font-family: 'Montserrat';
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: #5A5A5A;
      height: 77px
      background: #FFFFFF;
      box-shadow: 0px 0px 20px rgba(135, 211, 124, 0.15);
      position: relative;
      padding-left 70px
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__wrap
        margin-bottom 45px
        &.active
          .question__list__item__btn
            transition: all .4 ease
            background: #EDFFEA;
            &:after
              transform: rotate(180deg);
              border-top-color: #78A961;
      &__btn
        background: linear-gradient(360deg, #78A961 -45.03%, #CEFF67 108.94%);
        border-radius: 10px;
        display inline-block
        height: 77px;
        min-width: 77px;
        line-height: 89.5px
        text-align center
        flex-basis: 77px;
        &:after
          content: " "
          display inline-block
          width: 0;
          height: 0;
          border-left: 12.5px solid transparent;
          border-right: 12.5px solid transparent;
          border-top: 20px solid #fff;
      &__dropdown
        top 77px
        background: #F8FFF7;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        margin: 0 100px;
        padding: 20px 60px 35px;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #5A5A5A;
