.agree
    margin-top 18px
    display: flex
    align-items center
    justify-content center
    color: #B2B2B2;
    font-family: 'Open Sans';
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    //opacity .47
    // &-checkbox
    //     position relative
    //     width 9px
    //     height 9px
    //     border 1px solid currentColor
    //     margin-right 6px
    &__icon
        width 6px
        height 6px
        stroke: none
        box-shadow: 0px 4px 4px rgba(117, 160, 73, 0.31);
        fill: #A0D2A7
        &-wrapper
            display flex
            justify-content center
            align-items: center
            flex-shrink 0
            width 11px
            height 11px
            margin-right 6px
            border: 1px solid  currentColor
            border-radius: 2px
    &__text
        font-size 1rem
    &__link
        font-size 1rem
        text-decoration underline
