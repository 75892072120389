.call
    margin-top 10px
    &__icon
        width 16px
        height 16px
        color #78A961
        &-wrapper
            display flex
            justify-content: center
            align-items: center
            flex-shrink: 0
            width 26px
            height 26px
            margin-right 10px
            //background-color $primary
            border-radius 50%
            //box-shadow: 0 0 21px rgba($primary, .75)
    &__tel
        display flex
        justify-content: center
        align-items: center
        width 100%
        padding 7px 22px
        .header &
            //background-color $bgLight
            border-radius: 20px
        font-size: 1.8rem
        font-weight 800
        color: $primary
        opacity 1
        tro()
        span
            letter-spacing .025em
            color #5A5A5A
            font-family: 'Open sans'
            font-weight: bold
        &:hover
            opacity .7
    // &__link
    //     display block
    //     padding-left: 5px
    //     font-size: 1.6rem


@media $max-sm
    .call
        &__tel
            font-size: 1.6rem
        // &__link
        //     font-size 1.4rem
