.btn
    position relative
    display inline-flex
    align-items: center
    justify-content: center
    width: 100%
    min-height 95px
    padding: 24px 36px 26px
    color: #fff
    line-height 1.3
    letter-spacing 0.01em
    text-align center
    background: linear-gradient(360deg, #78A961 -45.03%, #CEFF67 108.94%);
    box-shadow: 0px 15px 35px rgba(117, 160, 73, 0.31);
    border-radius: 56px
    cursor: pointer
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    trbsh()
    btn-blick()
    span
        position relative
        z-index: 1
    &._shadow
        box-shadow: 0 4px 0px #66a02e, 0 27px 43px rgba($primary, .46)
        &:hover
            box-shadow: 0 5px 0px 1px #66a02e, 0 27px 53px rgba($primary, .56)
    &._center
        display flex
        margin-left auto
        margin-right auto
    &._ripple
        btn-ripple()


@media $max-sm
    .btn
        min-height 102px
        padding: 20px 30px 22px
        letter-spacing 0.01em
        font-size: 2rem
        border-radius: 50px


@media $max-xxs
    .btn
        min-height 82px
        padding: 10px 20px 12px
        letter-spacing 0.01em
        font-size: 1.8rem
        border-radius: 40px


.btn-video
    position relative
    display block
    flex-shrink: 0
    width 115px
    background: linear-gradient(360deg, #78A961 -45.03%, #CEFF67 108.94%);
    box-shadow: 0px 15px 35px rgba(117, 160, 73, 0.31);
    height 115px
    background-color $primary
    border-radius: 50%
    &::after
        content: ''
        position: absolute
        top 0
        left 0
        bottom 0
        right 0
        margin: auto
        width: 0
        height: 0
        border-top: 21px solid transparent
        border-left: 32px solid $light
        border-bottom: 21px solid transparent
        border-radius: 2px
        transform: translateX(3px)
        z-index: 3
    &::before
      content: " "
      position: absolute
      top -25px
      left 50%
      bottom 50%
      right -20px
      margin: auto
      z-index: 2
      background: url('../img/video-btn.png');
      background-repeat: no-repeat;

.form__btn
    transition: box-shadow .4s ease
    &:hover
        box-shadow: none
// @media $max-lg
    // .btn-video
    //     width 100px
    //     height 100px
