.video
    position relative
    display block
    height 182px
    width 320px 
    max-width: 100%
    margin-left auto
    margin-right auto
    border-top-left-radius: 18px
    border-top-right-radius: 18px
    overflow hidden
    &__img
        height 100%
        width 100%
        object-fit: cover
    &__btn
        position absolute
        z-index 1
        top 0
        left 0
        bottom 0
        right 0
        margin auto
        font-size: 0

// @media $max-md 
//     .video
       

// @media $max-xxs
//     .video
 