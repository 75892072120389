.review
    padding-top 130px
    padding-bottom 200px
    background url(../img/bg-review.png) top center no-repeat
    background-size 100% 100%
    &__text
        margin-bottom .9em
        font-weight normal
        letter-spacing .2em
        text-align center
        opacity 0.6
    &__title
        margin-bottom 1.25em
        letter-spacing .025em
        line-height 1.3
        text-align center
        &._box::after
            margin-top 22px
    &__list
        display flex
        justify-content space-between
        align-items: flex-start 
        width 100%
        max-width 960px
        margin-bottom 18px
        margin-left auto
        margin-right auto
    &__item
        width 50%
        max-width 420px  
    &__video
        min-height 220px
        padding 20px 30px 
        background-color $light
        box-shadow: 0 0 87px 30px rgba($dark, .14)   
        border-radius: 15px 
        &._short
            padding-top 50px
        &-row
            display flex
            justify-content space-between
            align-items: flex-start 
            margin-bottom 20px
        &-description
            margin-bottom 1.05em
            line-height 1.4
        &-title
            margin-bottom 0.5em    
        &-link
            display block 
            margin-bottom 0.3em     
        &-note
            margin-top 1.5em
            opacity 0.5   

@media $max-md 
    .review    
        padding-top 100px
        padding-bottom 120px
        background-size auto 100%
        &__list
            flex-wrap: wrap 
            margin-bottom 0    
        &__item
            width 420px 
            max-width 100%
            margin 0 auto 30px         

@media $max-xs 
    .review    
        padding-bottom 50px            