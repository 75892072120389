.methods
    &__text
        margin-bottom 1em
        font-weight normal
        letter-spacing .025em
        line-height 1.5
        text-align: center
        font-family: 'Montserrat'
    &__bg
        position: absolute;
        bottom: 0
        right: 0
        z-index: 0
    &__title
        margin-bottom 1.6em
        letter-spacing .05em
        line-height 1.4
        text-align: center
        margin-top 240px
    &__list
        width 100%
        //max-width 1068px
        display flex
        justify-content center
        align-items: flex-start
        flex-wrap: wrap;
        margin 4rem auto 12px
    &__item
        margin: 0 2em
        width calc(33.3333% - 4rem)
        //max-width 320px
        margin-bottom 20px
        z-index: 5
        background: #fff
        animation-duration: 1s
        &:nth-child(2)
          animation-delay: .5s
        &:nth-child(3)
          animation-delay: 1s
        &:nth-child(4)
          animation-delay: 1.5s
        &:nth-child(5)
          animation-delay: 2s
    &__description
        margin-top: 2rem
        margin-bottom 3em
        text-align: center
        line-height 1.4
        font-family: 'Montserrat'
        font-size: 1.8rem
        font-weight: 300
        color: #000
    &__btn
        max-width 390px
        letter-spacing: .025em
        box-shadow: 0px 15px 35px rgba(117,160,73,0.31);
        background: linear-gradient(360deg, #78a961 -45.03%, #ceff67 108.94%);
        font-size: 2rem
        &:hover
          box-shadow: none
    &__note
        text-align center


@media $max-md
    .methods
        &__list
            flex-wrap: wrap
            justify-content: space-around
            margin-bottom 0
        &__item
            width 320px
            max-width 100%
            margin-bottom 30px

@media $max-xs
    .methods
        &__btn
            max-width 360px

@media $max-xxs
    .methods
        &__btn
            max-width 320px
