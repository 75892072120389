*
*::after
*::before
    box-sizing border-box

body
html 
    font-family $ffPD 
    font-size: 62.5%
    font-weight: normal
    line-height 1.25

.wrap
    width 100%
    overflow hidden
    color: $dark
    background-color $bgLight

.wrapper  
    position relative

.container
    position relative
    width 100%
    max-width 1300px
    padding 0 10px
    margin: 0 auto  

.note
    width 100%
    margin-top 1.9em
    font-size: 1.3rem
    font-style: italic
    font-family $ffPD
    letter-spacing .025em
    color: $primary

select
input 
textarea
button
    font-family inherit
    color inherit 
    background-color transparent
    border: none 
    &::placeholder
        color: currentColor

*:focus
    outline: none

h1, h2, h3, h4, h5, h6
    font-weight bold
button, p, a
    font-weight 300
    font-family $ffG
b
    font-weight bold
a
    color: inherit


h1, h2, h3, h4, h5, h6, p, a, button
    &._box
        &::after
            content: ''
            display block
            width 200px
            max-width 56%
            height 44px
            margin 8px auto 0
            border: 1px solid $primary
            border-top none
    ._accent
        color $primary
    &._bold
    ._bold
        font-weight bold    
    &._extrabold
    ._extrabold
        font-weight 800    
    span 
        position relative    

h1
    font-size 4.4rem
    &._lg
        font-size: 4.8rem
h2 
    font-size: 3rem
    &._lg
        font-size: 4rem
h3 
    font-size 2.4rem
h4 
    font-size 2.2rem
h5 
    font-size 2rem
h6 
    font-size: 1.8rem
a, p
    font-size: 1.6rem    
    &._sm,
    ._sm
        font-size: 1.4rem
    &._lg,
    ._lg
        font-size: 1.8rem


@media $max-sm  
    h1
        font-size 3.4rem
        &._lg
            font-size: 4rem
    h2 
        font-size: 2.6rem
        &._md
        &._lg
            font-size: 3rem
    h3 
        font-size 2.2rem
        &._md
            font-size: 2.6rem
    h4 
        font-size 2rem
    h5 
        font-size 1.8rem

@media $max-xxs
    h1
        font-size 2.8rem
        &._lg
            font-size: 3.2rem
    h2 
        font-size: 2.4rem
        &._md
        &._lg
            font-size: 2.6rem
    h3 
        font-size 2rem
        &._md
            font-size: 2.4rem
    a, p
        font-size: 1.5rem    
        &._sm,
        ._sm
            font-size: 1.3rem
        &._lg,
        ._lg
            font-size: 1.7rem   

img 
    display: block
    
a
button
    cursor pointer
    &:focus
        outline: none

a
    text-decoration none    

button
    border none 
    background-color transparent
    padding 0    

svg 
    fill: currentColor         


// .wrap
//   width: 100%;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   overflow: hidden;
// .wrapper 
//   padding-top: 150px;
//   flex-grow: 1;  

.wrap._thanks
    min-height 100vh

.modals
    display: none
.modal 
    position relative    
    display inline-block

._hidden
    display: none    