@keyframes ripple
    0%
        transform translate(-50%, -50%) scale(0)
    18%
        transform translate(-50%, -50%) scale(1)
    20%
        transform translate(-50%, -50%) scale(0)

@keyframes ripple-play
    0%
        opacity 1
        transform translateY(-50%) scale(0)
    49%
        opacity 1
        transform translateY(-50%) scale(.62)
    98%
        transform translateY(-50%) scale(1.2)
        opacity 0
    100%
        transform translateY(-50%) scale(0)
        opacity 0

@keyframes ripple-4
    0%
        transform scale(1)
    14%
        transform scale(.5)
    24%
        transform scale(1)

@keyframes ripple-btn
    0%
        transform scale(1)
    25%
        transform scale(.9)
    75%
        transform scale(1.1)
    100%
        transform scale(1)

@keyframes ripple-btns
    0%
        transform scale(1)
    6.25%
        transform scale(.9)
    18.75%
        transform scale(1.1)
    25%
        transform scale(1)

@keyframes ripple-icons
    0%
        transform scale(1)
    8%
        transform scale(.7)
    24%
        transform scale(1.3)
    33%
        transform scale(1)

@keyframes ripple-phone
    0%
        transform skew(-25deg) scale(1)
    50%
        transform skew(-25deg) scale(1.2)
    // 25%
    //     transform skew(-25deg) scale(.9)
    // 75%
    //     transform skew(-25deg) scale(1.1)
    100%
        transform skew(-25deg) scale(1)

@keyframes waves
    0%
        transform scale(0)
    45%
        transform scale(1)
    100%
        transform scale(0)

@keyframes blinkend
    0%
        opacity 1
    20%
        opacity 0
    35%
        opacity 1
        
@keyframes blick
  0%
    left 0
  70%
    left 120%
  100%
    left 130%     

@keyframes appear
    0% 
        opacity 0
    16%
        opacity 1
    50%
        opacity 1    
    66%
        opacity 0   

@keyframes up-down-border
    0%
        transform translateY(0)        
    45%
        transform translateY(20px)        
    100%
        transform translateY(0)        

@keyframes move-3
    0%
        transform translateY(0)
    8%
        transform translateY(-50%)
    16%
        transform translateY(+50%)
    33%
        transform translateY(0)

@keyframes move-4
    0%
        transform translateY(0)
    6%
        transform translateY(-50%)
    12%
        transform translateY(+50%)
    24%
        transform translateY(0)

@keyframes move-5
    0%
        transform translateY(0)
    5%
        transform translateY(-50%)
    10%
        transform translateY(+50%)
    20%
        transform translateY(0)

@keyframes rotation 
    0% 
      transform: rotate(0)
    15% 
      transform: rotate(480deg)
    25% 
      transform: rotate(0)
    100% 
      transform: rotate(0)