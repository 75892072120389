@font-face
    font-family: 'Playfair Display';
    src:url('../fonts/playfairdisplay-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/playfairdisplay-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;

@font-face
    font-family: 'Playfair Display';
    src:url('../fonts/playfairdisplay-bold-webfont.woff2') format('woff2'),
         url('../fonts/playfairdisplay-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

@font-face
    font-family: 'Playfair Display';
    src:url('../fonts/playfairdisplay-italic-webfont.woff2') format('woff2'),
         url('../fonts/playfairdisplay-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

@font-face
    font-family: 'Playfair Display';
    src:url('../fonts/playfairdisplay-regular-webfont.woff2') format('woff2'),
         url('../fonts/playfairdisplay-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

// @font-face
//     font-family: 'Playfair Display';
//     src:url('../fonts/PlayfairDisplay-BoldItalic.woff2') format('woff2'),
//        url('../fonts/PlayfairDisplay-BoldItalic.woff') format('woff');
//     font-weight: bold;
//     font-style: italic;

// @font-face
//     font-family: 'Playfair Display';
//     src:url('../fonts/PlayfairDisplay-Bold.woff2') format('woff2'),
//        url('../fonts/PlayfairDisplay-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;

// @font-face
//     font-family: 'Playfair Display';
//     src:url('../fonts/PlayfairDisplay-Italic.woff2') format('woff2'),
//        url('../fonts/PlayfairDisplay-Italic.woff') format('woff');
//     font-weight: normal;
//     font-style: italic;

// @font-face
//     font-family: 'Playfair Display';
//     src:url('../fonts/PlayfairDisplay-Regular.woff2') format('woff2'),
//        url('../fonts/PlayfairDisplay-Regular.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;


@font-face
    font-family: 'Gilroy';
    src:url('../fonts/Gilroy-ExtraBold.woff2') format('woff2'),
       url('../fonts/Gilroy-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;

@font-face
    font-family: 'Gilroy';
    src:url('../fonts/Gilroy-Light.woff2') format('woff2'),
       url('../fonts/Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;

@font-face
    font-family: 'Merriweather';
    src:url('../fonts/Merriweather-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;

@font-face
    font-family: 'Montserrat';
    src:url('../fonts/Montserrat-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;

@font-face
    font-family: 'Open sans';
    src:url('../fonts/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
